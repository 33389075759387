import React from 'react';
import DefaultLayout from '../layouts/default';
import FluidImage from '../components/FluidImage.js';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';

export default ({ location }) => {
//  const dispatch = useDispatch();

//   React.useEffect(() => {

//   });


  return (
    <DefaultLayout location={location}>

      <Breadcrumbs location={location} />
      
      <div className="container is-layout">
        
        <section className="section">

          <div className="columns is-tablet is-centered">
            <div className="column is-8">
              <h1>Why we have a code of conduct?</h1>
              <p>&mdash; For us at Network Ventures working together and doing business with a lot of different people from diverse ethnical and personal backgrounds is the key to success. As our network grows and we are engaged in projects with people we don’t know for years (like we do our very first members), we had to put guidelines in place to make sure everybody is on the same page regarding the values that are most important to us. As mentioned above our code of conduct is a guideline and not a rulebook. So let’s get started.</p>
              
              <h4 className="mt-50">Our Network Ventures values in no specific order:</h4>
              <div className="tags are-medium mt-25">
                <span className="tag">Trustworthiness</span> 
                <span className="tag">Integrity</span> 
                <span className="tag">Honesty</span> 
                <span className="tag">Boldness</span> 
                <span className="tag">Fairness</span> 
                <span className="tag">Accountability</span> 
                <span className="tag">Fun</span> 
                <span className="tag">Result oriented</span> 
                <span className="tag">Constant Improvement</span> 
                <span className="tag">Diversity</span> 
                <span className="tag">Innovation</span> 
                <span className="tag">Quality</span> 
                <span className="tag">Teamwork</span> 
                <span className="tag">Partnership</span> 
                <span className="tag">Factfullness</span> 
                <span className="tag">Unselfishness</span> 
                <span className="tag">Self-Respect</span> 
              </div>
              
              <hr className="my-50"/>

              <h4 className="mt-25">Be aware</h4>
              <p className="is-normal">Becoming part of Network Ventures is like coming into a family. Everything you do as a member of Network Ventures and a representative will be reflected on the Network Ventures brand. It’s as easy as keeping your family name clean, don’t do stuff that could hurt your family's reputation.</p>

              <h4 className="mt-25">Integrity</h4>
              <p className="is-normal">Our reputation as a cooperative that our partners and members can trust is one of our most valuable assets. It is up to all of us to make sure that we continually earn that trust. All of our communications and other interactions with our members and partners should increase their trust in us.</p>

              <h4 className="mt-25">Honesty</h4>
              <p className="is-normal">Be honest. Don’t be afraid to say what you think even if it’s only your opinion. We don’t want to have people who bow to the strongest or most extroverted. Every opinion counts and has to be heard. Also make mistakes but don’t hide them. We all learn from our mistakes and it’s part of constant improvement to sometimes make a wrong decision. We are working in a cooperative because we love to help each other doing their best job.</p>

              <h4 className="mt-25">Accountability</h4>
              <p className="is-normal">You can (and will) be held accountable for your promises. If you are responsible for a project (or part of a project), it is your job to ensure that the tasks you committed yourself to get done in the quality and time all project partners agreed on. If you find out that’s not possible, let your project partners know, to find a way to make the project a success.</p>

              <h4 className="mt-25">Fairness</h4>
              <p className="is-normal">We endeavor to ensure that work performance and remuneration are in a fair relationship to one another. Don’t be greedy. You get your fair share of the project. Everything we invest in Network Ventures and it’s projects is based on fairness. If you feel that you’re not treated fairly, let your project partners know (honesty you remember?).</p>

              <h4 className="mt-25">Self-Respect</h4>
              <p className="is-normal">You are a valuable asset to the community at Network Ventures and that’s how you should be treated by every member and partner of Network Ventures.</p>

              <h4 className="mt-25">Unselfishness</h4>
              <p className="is-normal">We have a clear hierarchy for making tough decisions. From top to bottom, Network Ventures comes first. When a project or member puts the whole construct at risk, the cooperative comes first. Runner up is the specific project. It’s always more important what’s good for the project than personal beliefs of a single member.</p>

              <h4 className="mt-25">Factfullness</h4>
              <p className="is-normal">“The stress-reducing habit of only carrying opinions for which you have strong supporting facts” – Ola Rosling coined the term <i>factfulness</i> in order to describe the satisfaction of stating your opinion based on evidence instead of guessing. We believe in science and the current state of technology and are guided by the opinion of the scientific community. Facts are an important part of forming an opinion. There is no room in NVNetwork Ventures for speculation, conspiracy theories or alternative facts.</p>

              <h4 className="mt-25">Diversity</h4>
              <p className="is-normal">We make no distinction in terms of religion, ethnicity, gender or origin. </p>
              <p className="is-normal mt-25">Excursion to religion: If you believe in one or more gods or don't think anything like this exists, that's totally okay for us, but don't try to convince someone else of your opinion regarding religion. It's not your business. We accept every belief as long as it is not radical and does not harm people or our environment.</p>

              <h4 className="mt-25">Racism</h4>
              <p className="is-normal">No.</p>

              <h4 className="mt-25">Have Fun</h4>
              <p className="is-normal">The most important <i>rule</i> is: HAVE FUN. We are all part of Network Ventures to work together on exciting projects, pursue our passion and have a good time. Discussions are part of the business, but at the end of the day we should all have fun starting rockets.</p>

              <h4 className="mt-25">Golden Rule</h4>
              <p className="is-normal">Treat other people as you want to be treated.</p>

            </div>
          </div>

        </section>

      </div>

        
    </DefaultLayout>
  );
};

